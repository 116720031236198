// DEVELOPMENT CONFIG
let projectStatus = "DEV";
// projectStatus = "STAGING";
projectStatus = "LIVE";
// DEVELOPMENT CONFIG

let config = {
    "SITE_NAME": "Nirulas",
    "site_description": "Nirulas",
    "CONTACT": "1800-1025247",
    "GOOGLE_KEY": "AIzaSyBcWkVHVvjlyKpqT3POw-32IsfGnTFElqE",
    "RP_KEY_ID": "rzp_live_E7Q9VruGoJcuhi",
    "RP_KEY_SECRET": "n11U93PljxQ5zlzDCtVu6wnA",
    "Admin_Unique": "i_am_nirulas",

    order_status: {
        0: "Order Placed",
        1: "Order Accepted",
        2: "Order Ready for delivery",
        3: "Out for delivery",
        4: "Order Delivered",
        5: "Order Cancelled"
    },
    order_time: {
        from_time: "00:00",
        to_time: "23:59"
    },


    API_URL: "http://127.0.0.1:5001",
}

if (projectStatus === "DEV") {
    config.API = "http://127.0.0.1:5001/nirulas-api";
    config.IMAGE_URL = "https://api2.nirulas.com/nirulas-api/public";
    config.SITE_URL = "http://127.0.0.1:5001/";
    config.API_URL = "http://127.0.0.1:5001";
} else if(projectStatus === "STAGING") {
    config.API = "https://stagingapi.nirulas.com/nirulas-api";
    config.IMAGE_URL = "https://stagingapi.nirulas.com/nirulas-api/public";
    config.SITE_URL = "https://stagingapi.nirulas.com/nirulas-api/";
    config.API_URL = "https://stagingapi.nirulas.com/nirulas-api";
} else if(projectStatus === "LIVE") {
    config.API = "https://api2.nirulas.com/nirulas-api";
    config.IMAGE_URL = "https://api2.nirulas.com/nirulas-api/public";
    config.SITE_URL = "https://delivery.nirulas.com/";
    config.API_URL = "https://api2.nirulas.com";
    config.order_time = {
        from_time: "11:00",
        to_time: "23:00"
    }
}

export const constants = config;