import { io } from "socket.io-client";
import { constants } from "./Constants";

export let socket;
export const initiateSocketConnection = (token) => {
  socket = io(constants.API_URL, {
    auth: {
      Authorization: token,
    },
    transports: ["polling"],
  });

  console.log(`****** socket connected  *****`);

  socket.on("disconnect", (socket) => {
    console.log(`${socket} -- socket disconnected`);
  });

  return socket;
};

export const disconnectSocket = () => {
  if (socket) socket.disconnect();
};
