import React, { Suspense, lazy, useRef, useEffect } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { io } from "socket.io-client";
import { constants } from "./Constants";
import spinner from "./images/spinner.gif";
import PrivateRoute from "./PrivateRoute";
import { initiateSocketConnection } from "./socketio.service";

const Home = lazy(() => import('./components/pages/Home'));
const PlaceOrder = lazy(() => import('./components/pages/PlaceOrder'));
const Login = lazy(() => import('./components/auth/Login'));
const Dashboard = lazy(() => import('./components/auth/Dashboard'));
const Reports = lazy(() => import('./components/auth/Reports'));
const Address = lazy(() => import('./components/auth/Address'));
const Orders = lazy(() => import('./components/auth/Orders'));
const ProductStock = lazy(() => import('./components/auth/ProductStock'));
const ProductStockNew = lazy(() => import('./components/auth/ProductStockNew'));
const PrintKOT = lazy(() => import('./components/pages/PrintKOT'));
const PrintOrder = lazy(() => import('./components/pages/PrintOrder'));
const Inventory = lazy(() => import('./components/auth/Inventory'));
const Profile = lazy(() => import('./components/auth/Profile'));
const CustomerPOS = lazy(() => import('./components/pages/CustomerPOS'));
const InventorySku = lazy(() => import('./components/auth/InventorySku'));

function App() {
    const socket = useRef();

    // socket connection
    useEffect(() => {
        let token = "";
        try { token = window.getCookie("token") } catch (e) { console.log('error') };
        socket.current = initiateSocketConnection(token);

        const branch_id = window?.localStorage?.getItem('branch_id') ?? false;
        if (branch_id) socket?.current?.emit('add_branch', { branch_id });
    }, [])


    return (
        <Suspense fallback={<div className="loader-default"><img src={spinner} alt="Loading" className="loader-spinner" /></div>}>
            <Router>
                <PrivateRoute path="/dashboard" component={Dashboard} />
                <PrivateRoute path="/profile" component={Profile} />
                <PrivateRoute path="/reports" component={Reports} />
                <PrivateRoute path="/address" component={Address} />
                <PrivateRoute path="/inventory" component={Inventory} />
                <PrivateRoute path="/orders" component={Orders} />
                <PrivateRoute path="/stock-old" component={ProductStock} />
                <PrivateRoute path="/stock" component={ProductStockNew} />
                <PrivateRoute path="/print-kot/:id" component={PrintKOT} />
                <PrivateRoute path="/print-order/:id" component={PrintOrder} />
                <PrivateRoute path="/inventory-sku" component={InventorySku} />

                <Route exact={true} path="/" render={(props) => <Home {...props} customerSocket={socket} />} />
                <Route exact={true} path="/login" component={Login} />
                <Route exact={true} path="/place-order" component={PlaceOrder} />
                <Route exact={true} path="/customer-pos" render={(props) => <CustomerPOS {...props} socket={socket} />} />
            </Router>
        </Suspense>
    );
}

export default App;
